@import './variables';

* {
	font-family: $app-font;
}
html,
body,
#root {
	height: 100%;
	overflow: auto;
	margin: 0;
}

body,
input,
button,
span,
p,
label {
	font-size: $font-size;
}

// Basic button styling
button {
	display: block;

	padding: $padding;

	background-color: $dark;
	color: $light;

	border: 1px solid $dark;
	border-radius: 0;

	text-align: center;
	text-decoration: none;
	font-weight: bold;
}

.il-primary {
	fill: $primary;
}

.sura {
	.il-primary {
		fill: $sura_primary;
	}
}

.csm {
	.il-primary {
		fill: $csm_primary;
	}
}

.lafise {
	.il-primary {
		fill: $lafise_primary;
	}
}

.oceanica {
	.il-primary {
		fill: $oceanica_secondary;
	}
}

.qualitas {
	.il-primary {
		fill: $qualitas_primary;
	}
}

.assa {
	.il-primary {
		fill: $assa_primary;
	}
}

.segurosmundial {
	.il-primary {
		fill: $seguros_mundial_primary;
	}
}

.multinational {
	.il-primary {
		fill: $multinational_primary;
	}
}

.premier {
	.il-primary {
		fill: $premier_primary;
	}
}
