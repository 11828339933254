.alert-env {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  picture {
    width: 57%;
    position: absolute;
    text-align: center;
    top: 39px;

    img {
      width: 50%;
    }
  }

  .screen-img {
    margin-top: 4rem;
  }

  .smallImg{
    img {
      width: 150px;
    }
  }

  .qualitasImg{
    img {
      width: 120px;
    }
  }

  h2 {
    //box-sizing: border-box;
    text-align: center;
    padding: 0 13px;
  }
  h3 {
    position: absolute;
    bottom: 0;
    font-weight: 500;
    font-size: 14px;

    span {
      font-weight: 700;
      font-size: 16px;
      color: black;
    }
  }
  #logo {
    max-width: 27rem;
  }
  #screen-img {
    position: inherit;
    max-width: 30rem;
    max-height: 23rem;
  }
  p {
    font-size: 1.5rem;
    padding: 0 2px;
  }
}

@media screen and (max-width: 1200px) and (min-width: 601px) {
  #screen-img {
    height: 325px;
    width: 660px;
  }
}

@media screen and (max-width: 600px) and (min-width: 100px) {
  #logo {
    width: 100%;
  }
  #screen-img {
    height: 225px;
    width: 460px;
  }
  p {
    text-align: center;
  }
}
