@import '../variables';

button.round-btn {
	border-color: $light-gray;
	border-radius: 45px;
	padding: 8px 0;
	font-size: 95%;
	background-color: $light-gray;
	color: $dark-gray;
}
button.round-btn.checked {
	border-color: $dark;
	background-color: $dark;
	color: $light;
}

.csm button.round-btn.checked {
	border-color: $csm_primary;
	background-color: $csm_primary;
	color: $light;
}

.oceanica button.round-btn.checked {
	border-color: $oceanica_secondary;
	background-color: $oceanica_secondary;
	color: $light;
}

.qualitas button.round-btn.checked {
	border-color: $qualitas_primary;
	background-color: $qualitas_primary;
	color: $light;
}

.multinational button.round-btn.checked {
	border-color: $multinational_primary;
	background-color: $multinational_primary;
	color: $light;
}
.premier button.round-btn.checked {
	border-color: $premier_primary;
	background-color: $premier_primary;
	color: $light;
}