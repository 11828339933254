@import '../../_variables';

$damage-window-height: 374px;

.damage-window-container {
	position: fixed;
	top: 0;
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: rgba($dark, 0.7);

	.damage-window {
		animation: open 0.3s;
		display: flex;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 20px;
		min-width: 250px;
		max-width: 80%;
		min-height: $damage-window-height;
		margin: 0 auto;

		.container {
			-webkit-box-shadow: 0px 0px 38px -18px rgba(0, 0, 0, 0.75);
			-moz-box-shadow: 0px 0px 38px -18px rgba(0, 0, 0, 0.75);
			box-shadow: 0px 0px 38px -18px rgba(0, 0, 0, 0.75);
			text-align: center;
			padding: $padding;
			background-color: $light;
			border-radius: 10px;

			.subtitle {
				color: $dark-gray;
			}

			img {
				align-self: flex-end;
				width: 20px;
				height: 20px;
				padding-left: 10px; // this paddings are to give a litle bit more of clicking area while making the button smaller.
				padding-bottom: 10px;
			}

			h4,
			h5 {
				margin: 0;
				margin-bottom: 3px;
			}
			h4 {
				font-size: 105%;
			}
			h5 {
				font-size: 90%;
			}

			.radio-container {
				margin: $padding;
				display: flex;
				flex-direction: column;
				justify-content: space-evenly;

				button {
					border-radius: 5px;
					padding: 10px 0;
				}
				.round-btn {
					margin-bottom: $padding;
				}
			}
			button.sm {
				font-size: 100%;
				padding: 10px 0;
				margin: 0 16px;
				border-radius: 5px;
			}
		}
	}
}
.hidden {
	display: none;
}

.oceanica .damage-window {
	.container {
		button {
			border: 1px solid #fff;
		}
		button.sm {
			background-color: $oceanica_secondary;
			border: 1px solid $oceanica_secondary;
		}
	}
}

.csm .damage-window {
	.container {
		button {
			border: 1px solid #fff;
		}
		button.sm {
			background-color: $csm_backgroundColor;
			border: 1px solid $csm_primary;
			color: $csm_primary;
		}
	}
}

.qualitas .damage-window {
	.container {
		button {
			border: 1px solid #fff;
		}
		button.sm {
			background-color: $qualitas_primary;
			border: 1px solid $qualitas_primary;
		}
	}
}

.multinational .damage-window {
	.container {
		button {
			border: 1px solid #fff;
		}
		button.sm {
			background-color: $multinational_backgroundColor;
			border: 1px solid $multinational_primary;
			color: $multinational_primary;
		}
	}
}

.premier .damage-window {
	.container {
		button {
			border: 1px solid #fff;
		}
		button.sm {
			background-color: $premier_backgroundColor;
			border: 1px solid $premier_primary;
			color: $premier_primary;
		}
	}
}

@keyframes open {
	from {
		bottom: -400px;
	}
	to {
		bottom: 20px;
	}
}
