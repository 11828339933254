@import '../../variables';

.camera-screen {
	height: 100%;
	display: flex;
	flex-direction: column;

	.horizontal-warning {
		height: 100vh;
		width: 100%;
		background-image: url(../../imgs/connect/brand_bg.png);
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;

		h2 {
			color: white;
		}
	}

	.header {
		color: white;

		span {
			font-size: 20px;
		}

		.info-icon {
			background-color: white;
			border-radius: 50%;
			height: 28px;
			width: 28px;
		}
	}

	p {
		color: white;
	}

	.camera-viewfinder {
		flex: 1;
	}

	.camera-options {
		overflow: auto;
		margin: auto auto 0;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		.photos {
			display: flex;
			flex-direction: column;
			&__hidePhotos {
				position: relative;
				z-index: -2;
			}

			.photo-description {
				margin-bottom: calc(#{$padding} * 1.5);
				color: $light;
			}
		}
		.camera-shutter {
			flex: 1;
			height: 100px;
			.container {
				height: 100%;
			}
		}

		.button-container {
			display: flex;
			flex-direction: row;
			flex: 1;
			.confirm-button {
				align-self: flex-end;
				width: 100%;
			}
		}
	}

	.completed {
		background-image: url(../../imgs/connect/brand_bg.png);
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		height: 100%;
		width: 100%;
		z-index: 1;
		position: absolute;
		color: $light;
		h1 {
			font-size: 22px;
		}
		img {
			align-self: center;
			height: 120px;
			width: 120px;
		}
		p {
			margin: 0;
			font-size: 1rem;
			color: $light;
		}
		.checkmark {
			height: 98px;
			width: 90px;
		}
		.photoIcon {
			height: 16rem;
			width: 17rem;
			margin-top: -8rem;
		}
		.message {
			margin: 3rem 1rem 0rem;
		}

		.continue {
			background-color: $light;
			border: none;
			display: block;

			margin: $padding;
			padding: $padding;

			color: $primary;
			border-radius: 11px;

			font-size: 120%;
			text-align: center;
			text-decoration: none;
			font-weight: bold;
			cursor: pointer;

			position: fixed;
			bottom: 0;
			width: calc(100% - 32px);
			box-sizing: border-box;
		}
		&__container {
			height: 100%;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
		}
	}
	.fab {
		float: right;
		position: absolute;
		top: 0.2rem;
		right: 9px;
	}
}
.window-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	.checkmark {
		display: flex;
		flex-direction: column;
		justify-content: center;
		border-radius: 200px;
		align-items: center;
		background-color: $primary;
		width: 70px;
		height: 70px;
		margin: 16px;
		img {
			padding: 4px;
			margin: 0px;
			height: 60px;
			width: 60px;
		}
	}
	.warningAlert {
		display: flex;
		flex-direction: column;
		justify-content: center;
		border-radius: 200px;
		align-items: center;
		width: 70px;
		height: 70px;
		margin: 16px;
		img {
			padding: 4px;
			margin: 0px;
			height: 60px;
			width: 60px;
		}
	}
	p {
		margin: 8px 0; // important to prevent override from component
		color: $default;
	}
	a {
		display: flex;
		align-items: center;
		justify-content: space-around;
		text-decoration: none;
		color: $kinda-white;
		background-color: $scorpion;
		height: 2.5rem;
		width: 100%;
		border-radius: 10px;
	}
}

.sura {
	.camera-screen {
		.horizontal-warning {
			background-image: url(../../imgs/sura/brand_bg.png);
		}
		.camera-tooltip {
			background-color: $sura_primary;
		}
		.completed {
			background-image: url(../../imgs/sura/brand_bg.png);
			color: white;
			p {
				color: $light;
			}
			.continue {
				color: $sura_primary;
			}
		}
	}
	.window-content {
		.checkmark {
			background-color: $sura_primary;
		}
	}
}
.csm {
	.camera-screen {
		.horizontal-warning {
			background-image: none;
			background-color: #fff;
			h2 {
				color: #000;
			}
		}
		.camera-tooltip {
			background-color: $csm_primary;
		}
		.camera-tooltip-container {
			background-color: $csm_primary;
		}
		.completed {
			background-image: none;
			background-color: #fff;
			color: #000;
			p {
				color: #000;
			}

			.continue {
				color: $csm_primary;
			}
		}
	}
	.window-content {
		.checkmark {
			background-color: $csm_primary;
		}
	}
}
.lafise {
	.camera-screen {
		.horizontal-warning {
			background-image: none;
			background-color: $lafise_secondary;
		}
		.camera-tooltip {
			background-color: $lafise_primary;
		}
		.completed {
			background-image: none;
			background-color: $lafise_secondary;
			color: #fff;
			p {
				color: #fff;
			}
			.continue {
				color: $lafise_primary;
			}
		}
	}
	.window-content {
		.checkmark {
			background-color: $lafise_primary;
		}
	}
}
.oceanica {
	.camera-screen {
		.horizontal-warning {
			background-image: none;
			background-color: $oceanica_secondary;
		}
		.camera-tooltip {
			background-color: $oceanica_primary;
		}
		.completed {
			background-image: none;
			background-color: $oceanica_secondary;
			color: #fff;
			p {
				color: #fff;
			}
			.continue {
				color: $oceanica_primary;
			}
		}
	}
	.window-content {
		.checkmark {
			background-color: $oceanica_primary;
		}
	}
}

.qualitas {
	.camera-screen {
		.horizontal-warning {
			background-image: none;
			background-color: $qualitas_primary;
		}
		.camera-tooltip {
			background-color: $qualitas_primary;
		}
		.completed {
			background-image: none;
			background-color: $qualitas_primary;
			color: #fff;
			p {
				color: #fff;
			}
			.continue {
				color: $qualitas_primary;
			}
		}
	}
	.window-content {
		.checkmark {
			background-color: $qualitas_primary;
		}
	}
}

.assa {
	.camera-screen {
		.horizontal-warning {
			background-image: none;
			background-color: $assa_primary;
		}
		.camera-tooltip {
			background-color: $assa_primary;
		}
		.completed {
			background-image: none;
			background-color: $assa_primary;
			color: #fff;
			p {
				color: #fff;
			}
			.continue {
				color: $assa_primary;
			}
		}
	}
	.window-content {
		.checkmark {
			background-color: $assa_primary;
		}
	}
}

.segurosmundial {
	.camera-screen {
		.horizontal-warning {
			background-image: none;
			background-color: $seguros_mundial_primary;
		}
		.camera-tooltip {
			background-color: $seguros_mundial_primary;
		}
		.completed {
			background-image: none;
			background-color: $seguros_mundial_primary;
			color: white;
			p {
				color: $light;
			}
			.continue {
				color: $seguros_mundial_primary;
			}
		}
	}
	.window-content {
		.checkmark {
			background-color: $seguros_mundial_primary;
		}
	}
}

.multinational {
	.camera-screen {
		.horizontal-warning {
			background-image: none;
			background-color: #fff;
			h2 {
				color: #000;
			}
		}
		.camera-tooltip {
			background-color: $multinational_primary;
		}
		.camera-tooltip-container {
			background-color: $multinational_primary;
		}
		.completed {
			background-image: none;
			background-color: #fff;
			color: #000;
			p {
				color: #000;
			}

			.continue {
				color: $multinational_primary;
			}
		}
	}
	.window-content {
		.checkmark {
			background-color: $multinational_primary;
		}
	}
}

.premier {
	.camera-screen {
		.horizontal-warning {
			background-image: none;
			background-color: #fff;
			h2 {
				color: #000;
			}
		}
		.camera-tooltip {
			background-color: $premier_primary;
		}
		.camera-tooltip-container {
			background-color: $premier_primary;
		}
		.completed {
			background-image: none;
			background-color: #fff;
			color: #000;
			p {
				color: #000;
			}

			.continue {
				color: $premier_primary;
			}
		}
	}
	.window-content {
		.checkmark {
			background-color: $premier_primary;
		}
	}
}