@import '../../variables';

.sketch-screen {
	height: 100%;
	display: flex;
	flex-grow: 1;
	margin: 0;
	flex-direction: column;

	.confirm-button {
		display: block;
		margin: $padding;
		margin-top: 0;
		padding: $padding;
		background-color: $dark;
		color: $light;
		border: 1px solid $dark;
		border-radius: 11px;
		font-size: 120%;
		text-align: center;
		text-decoration: none;
		font-weight: bold;
	}
	button:disabled.confirm-button {
		pointer-events: none;
		background-color: $dark-gray;
		border-color: $dark-gray;
	}
	.sketch-map {
		display: flex;
		justify-content: center;
		flex-direction: column;
		flex: 1;

		#sketch-container {
			flex: 1;
			position: relative; // so that icons are absolute w.r.t to this.
			background-color: $light;

			.drawing {
				background-size: cover;
				background-position: center;
				height: 100%;
				width: 100%;
			}

			.icon {
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				margin: auto;
				position: absolute;
				width: 20vw;
				height: 20vw;
				transition: box-shadow 0.5s, opacity 0.5s;
				will-change: transform;
				cursor: grab;

				img {
					width: 20vw;
					height: 20vw;
				}

				&--medium {
					img {
						width: 14vw;
						height: 14vw;
					}
				}
				&--small {
					img {
						width: 10vw;
						height: 10vw;
					}
				}

				z-index: 1;
			}
			.icon.dragging {
				cursor: grabbing;
			}

			.icon.disabled {
				opacity: 0.5;
				cursor: default;
			}

			button.eraserIcon,
			button.info,
			button.drawIcon,
			button.rotateIcon {
				position: absolute;
				border-radius: 100px;
				height: 30px;
				width: 30px;
				top: 15px;
				right: 10px;
				float: right;
				color: $dark;
				text-align: center;
				padding: 0;
				z-index: 50;
				height: 42px;
				width: 42px;
				box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.08);
				-webkit-box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.08);
				-moz-box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.08);
				background-color: white;
				border-color: hsla(0, 0%, 0%, 0);
				cursor: pointer;

				&.active {
					background-color: $primary;
				}

				&:active,
				&:focus {
					outline: none;
				}

				img {
					height: 25px;
					margin-top: 3px;
					margin-left: 2px;
					pointer-events: none;
				}
			}

			button.info {
				img {
					height: 45px;
					width: 45px;
					margin-left: -1px;
				}
			}

			button.rotateIcon {
				margin-top: 3rem;
			}

			button.drawIcon {
				margin-top: 6rem;
			}

			button.eraserIcon {
				margin-top: 9rem;
			}

			button.delete {
				position: absolute;
				height: 50px;
				width: 50px;
				bottom: 25px;
				right: 5px;
				padding: 0;
				background-color: $primary;
				border-radius: 50px;
				border: none;
				cursor: pointer;
				z-index: 50;

				&:active,
				&:focus {
					outline: none;
				}

				img {
					height: 50px;
					width: 50px;
				}
			}
		}
	}
	.iconRotateSketch {
		height: 1.2rem;
		width: 1.5rem;
		position: absolute;
		bottom: 61px;
		right: -12px;
	}
	.areaRotateSketch {
		border: 3px dotted;
		border-color: #504f4f;
	}
}

.sketch-screen .sketch-map #sketch-container .icon .selectedIconSketch {
	width: 18vw;
	height: 18vw;
}

.message-window {
	img.action-gif {
		margin-top: $padding;
		height: 90px;
	}
	.rotate-gesture-page {
		img {
			width: 17rem;
			margin-left: -1rem;
			height: 5.7rem;
		}
	}
	@media (max-width: 370px) {
		.rotate-gesture-page {
			img {
				width: 13rem;
				margin-left: -0.5rem;
				height: 4rem;
			}
		}
	}
	@media (max-width: 280px) {
		.rotate-gesture-page {
			img {
				width: 11rem;
				margin-left: -0.8rem;
				height: 4rem;
			}
		}
	}
}

.sura .sketch-screen .confirm-button {
	background-color: $sura_primary;
	border: 1px solid $sura_primary;
}

.csm {
	.sketch-screen {
		background-color: $csm_backgroundColor;
		.confirm-button {
			background-color: $csm_primary;
			border: 1px solid $csm_primary;
		}
	}
}

.lafise .sketch-screen .confirm-button {
	background-color: $lafise_primary;
	border: 1px solid $lafise_primary;
}

.oceanica .sketch-screen .confirm-button {
	background-color: $oceanica_primary;
	border: 1px solid $oceanica_primary;
}

.qualitas .sketch-screen .confirm-button {
	background-color: $qualitas_primary;
	border: 1px solid $qualitas_primary;
}

.assa .sketch-screen {
	background-color: $assa-bg;
	.confirm-button {
		background-color: $assa_primary;
		border: 1px solid $assa_primary;
	}
}

.segurosmundial .sketch-screen .confirm-button {
	background-color: $seguros_mundial_primary;
	border: 1px solid $seguros_mundial_primary;
}

.multinational {
	.sketch-screen {
		background-color: $multinational_backgroundColor;
		.confirm-button {
			background-color: $multinational_primary;
			border: 1px solid $multinational_primary;
		}
	}
}

.premier {
	.sketch-screen {
		background-color: $premier_backgroundColor;
		.confirm-button {
			background-color: $premier_primary;
			border: 1px solid $premier_primary;
		}
	}
}
