@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');

@font-face {
	font-family: 'Trade Gothic';
	src: url('./fonts/TradeGothicLT.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Trade Gothic';
	src: url('./fonts/TradeGothicLTBold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Trade Gothic';
	src: url('./fonts/TradeGothicLTLight.ttf') format('truetype');
	font-weight: lighter;
	font-style: normal;
}

@font-face {
	font-family: 'Frutiger';
	src: url('./fonts/FrutigerLTStd-Roman.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Frutiger';
	src: url('./fonts/FrutigerLTStd-Bold.otf') format('opentype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Frutiger';
	src: url('./fonts/FrutigerLTStd-Light.otf') format('opentype');
	font-weight: lighter;
	font-style: normal;
}
