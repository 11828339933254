@import '../../variables';

.splash {
	height: 100vh;
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	.main-info {
		margin-top: 12rem;
		text-align: center;
	}
	.logo {
		height: 25vmin;
		pointer-events: none;
	}
}

.sura .splash {
	.main-info {
		margin-top: 16.5rem;
	}
}

.csm .splash {
	justify-content: flex-start;
	background-color: $csm_backgroundColor;
	color: $csm_thumbnailSelected;
	.main-info {
		margin-top: 10rem;
		.logo {
			height: 80vmin;
		}
	}
}

.lafise .splash {
	color: $lafise_thumbnailSelected;
	.logo {
		height: 40vmin;
		width: 81vmin;
	}
}

.oceanica .splash {
	color: $oceanica_thumbnailSelected;
	.logo {
		height: 63vmin;
		width: 81vmin;
	}
}

.qualitas .splash {
	.logo {
		height: 63vmin;
		width: 81vmin;
	}
}

.assa .splash {
	color: $assa_primary;
	.logo {
		height: 63vmin;
		width: 81vmin;
	}
}

.segurosmundial .splash {
	background-color: $seguros_mundial_primary;
	.main-info {
		margin-top: 16.5rem;

		h3 {
			color: #ffffff;
		}
	}
	p {
		color: #ffffff;
	}
}

.multinational .splash {
	color: $multinational_primary;
	font-size: 14px;
	.logo {
		height: 30vmin;
	}
}
.premier .splash {
	background-color: $premier_backgroundColor;
	color: $premier_thumbnailSelected;
	.main-info {
		margin-top: 10rem;
	}
	.logo {
		height: 15vmin;
	}
}
