@import '../../variables';

.client-form {
	display: flex;
	margin: 0;
	flex: 1;
	flex-direction: column;

	.container {
		flex: 1;
		margin: 0 $padding;
		display: flex;
		flex-direction: column;
		.confirm-button {
			margin: 0;
		}
	}

	.fields-container {
		flex: 1;
		margin: 20px $padding;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		.confirm-button {
			margin: 0;
		}
	}

	.error {
		text-align: center;
	}

	.checkbox-form {
		display: flex;
		align-items: center;
		position: relative;
		padding-left: 35px;
		margin-bottom: 12px;
		margin-top: 22px;
		margin-left: 7px;
		cursor: pointer;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		padding-top: 5px;

		label {
			color: $dark-gray;
		}
	}

	.checkbox-input {
		width: 25px;
		height: 25px;
	}

	.select-data {
		display: flex;
		flex-direction: column;
		flex: 1 1;
		margin: 10px 4;
		position: relative;

		label {
			position: absolute;
			top: 15px;
			left: 20px;
			margin-bottom: 10px;
			color: $dark-gray;
			transition: 0.2s ease all;
			font-size: 18px;
		}

		select {
			padding: 13px;
			border: 1px solid #979797;
			border-radius: 5px;
			background-color: #fbfbfb;
			font-size: 1rem;
			margin: 0 8px;
			-webkit-appearance: none;
		}

		select:focus ~ label,
		select:valid ~ label {
			padding: 0 5px;
			top: -7px;
			left: 10px;
			font-size: 12px;
		}
	}
}

.checkbox-form input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

.checkmark-input {
	position: absolute;
	top: 0;
	left: 0;
	height: 25px;
	width: 25px;
	background-color: $light;
	border: 1px solid $dark-gray;
	border-radius: 15px;
}

/* When the checkbox is checked, add a blue background */
.checkbox-form input:checked ~ .checkmark-input {
	background-color: #2196f3;
}

.checkmark-input:after {
	content: '';
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.checkbox-form input:checked ~ .checkmark-input:after {
	display: block;
}

/* Style the checkmark/indicator */
.checkbox-form .checkmark-input:after {
	left: 9px;
	top: 5px;
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.date-input {
	margin: 0 8px;
	.MuiFormLabel-root.Mui-focused {
		color: $dark-gray;
	}
	.MuiFormControl-fullWidth {
		background-color: $kinda-white;
	}
}

.select-input {
	margin: 0 8px;
	.MuiFormLabel-root {
		color: $dark-gray;
	}
	.MuiFormLabel-root.Mui-focused {
		color: $dark-gray;
		margin-top: 0px;
	}
	.MuiInputBase-root {
		height: 47px;
	}
	.MuiOutlinedInput-notchedOutline {
		border: 1px solid $dark-gray;
	}

	.MuiOutlinedInput-root {
		background-color: $kinda-white;
	}
	.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
		border-color: $danger;
		background-color: $kinda-white;
	}
	.MuiFormHelperText-root.Mui-error {
		margin: 0;
	}
	.MuiFormHelperText-root.Mui-error,
	.MuiFormLabel-root.Mui-error {
		background-color: transparent;
		font-family: $app-font;
		color: $danger;
	}
	.MuiFormControl-root {
		width: 100%;
	}
}

.csm .client-form {
	background-color: $csm_backgroundColor;

	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
		border: 1px solid $csm_primary;
	}
	select:focus ~ label,
	select:valid ~ label {
		background-color: $csm_backgroundColor;
	}
}

.sura .client-form {
	select:focus ~ label,
	select:valid ~ label {
		background-color: $light;
	}
	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
		border: 1px solid $sura_primary;
	}
}

.lafise .client-form {
	select:focus ~ label,
	select:valid ~ label {
		background-color: $light;
	}
	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
		border: 1px solid $lafise_primary;
	}
}

.qualitas .client-form {
	select:focus ~ label,
	select:valid ~ label {
		background-color: $light;
	}
	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
		border: 1px solid $qualitas_primary;
	}
}

.oceanica .client-form {
	select:focus ~ label,
	select:valid ~ label {
		background-color: $light;
	}
	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
		border: 1px solid $oceanica_primary;
	}
}

.assa .client-form {
	background-color: $assa-bg;
	select {
		outline: none;
	}
	select:focus ~ label,
	select:valid ~ label {
		background-color: $light;
		background: linear-gradient(0deg, $kinda-white 0%, $kinda-white 45%, $assa-bg 45%, $assa-bg 100%);
	}
	select:focus {
		border-color: $assa_primary;
	}
	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
		border: 1px solid $assa_primary;
	}
}

.segurosmundial .client-form {
	select:focus ~ label,
	select:valid ~ label {
		background-color: $light;
	}
	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
		border: 1px solid $seguros_mundial_primary;
	}
}

.multinational .client-form {
	background-color: $multinational_backgroundColor;

	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
		border: 1px solid $multinational_primary;
	}
	select:focus ~ label,
	select:valid ~ label {
		background-color: $multinational_backgroundColor;
	}
}

.premier .client-form {
	background-color: $premier_backgroundColor;

	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
		border: 1px solid $premier_primary;
	}
	select:focus ~ label,
	select:valid ~ label {
		background-color: $premier_backgroundColor;
	}
}
