@import '../../variables';

.location {
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow-y: scroll;

	.confirm-button {
		margin-top: 16px;
	}
	&__accidentDate {
		display: flex;
		flex-direction: column;
		margin-bottom: 6rem;
	}
	&__date {
		margin: 0.5rem 1rem;
	}
	&__time {
		margin: 0.5rem 1rem;
	}
}

.csm .location-search .input-container input {
	background-color: $light;
}

.csm .location {
	background-color: $csm_backgroundColor;
}

.multinational .location-search .input-container input {
	background-color: $light;
}

.multinational .location {
	background-color: $csm_backgroundColor;
}

.assa {
	.location {
		background-color: $assa-bg;
	}
	.MuiFormControl-fullWidth {
		background-color: $kinda-white;
	}
}

.premier .location-search .input-container input {
	background-color: $light;
}

.premier .location {
	background-color: $premier_backgroundColor;
}
