@import 'variables';

.app {
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow-y: scroll;
}

.csm * {
	font-family: Frutiger, Helvetica, Arial, sans-serif;
}

.segurosmundial * {
	font-family: 'Ubuntu', sans-serif;
}

.multinational * {
	font-family: 'Arial', sans-serif;
}

.premier * {
	font-family: Frutiger, Helvetica, Arial, sans-serif;
}