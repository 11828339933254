@import '../../variables';

.narration-screen {
	height: 100%;
	display: flex;
	flex-grow: 1;
	margin: 0;
	flex-direction: column;

	.progress-bar {
		order: 1;
	}
	.header {
		order: 2;
	}
	.body {
		order: 3;
	}

	.container {
		display: flex;
		flex: 1;
		flex-direction: column;

		.body {
			display: flex;
			flex-direction: column;
			flex-grow: 1;
			justify-content: space-between;

			textarea {
				border-color: $gray;
				resize: none;
				margin: 0 20px 20px 20px;
				padding: 20px 16px;
				font-size: 16px;
				line-height: 24px;
				-webkit-appearance: none;
				border-radius: 0;
				box-shadow: 0;
			}
		}
	}
}

.csm .narration-screen {
	background-color: $csm_backgroundColor;
}

.assa .narration-screen {
	background-color: $assa-bg;
}

.multinational .narration-screen {
	background-color: $multinational_backgroundColor;
}

.premier .narration-screen {
	background-color: $premier_backgroundColor;
}
